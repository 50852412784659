<template>
  <div class="blog_edit_box">
    <div class="back-blogs" @click="back">
      <el-link :underline="false" icon="el-icon-back" class="back-icon">Back to Blog</el-link>
    </div>
    <div class="blog_edit_info blog_upload">
      <div style="max-width:1140px;">
        <h3 class="add-new-blog">{{ title }}</h3>
      <el-form ref="blog" :model="blog" :rules="rules">
        <el-form-item prop="title" :rules="rules.required">
          <p class="text-tip">Add your title</p>
          <el-input v-model="blog.title"></el-input>
        </el-form-item>
        <el-form-item prop="content" class="ckeditor" :rules="rules.required">
          <Ckeditors ref="edit" :data="blog.content"></Ckeditors>
        </el-form-item>
        <el-form-item prop="summary" :rules="rules.required">
          <p class="text-tip">Meta Description</p>
          <el-input
            type="textarea"
            maxlength="156"
            placeholder="A good meta description will drive search engine traffic to your page, so be descriptive but keep it under 155 characters."
            resize="none" v-model="blog.summary" :autosize="{ minRows: 4 }"></el-input>
        </el-form-item>
        <el-form-item prop="cover" :rules="rules.required">
          <div class="upload_Img row">
            <div class="upload-basic" v-loading="loadingImg" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" >
              <el-upload
                class="avatar-uploader"
                action="uploader"
                :show-file-list="false"
                :http-request="uploadCover" >
                <img v-if="blog.cover" :src="blog.cover" class="avatar" />
                <div v-else class="uploader_boder">
                  <i class="el-icon-upload2 avatar-uploader-icon"></i>
                  <p>Feature Image</p>
                  <!-- <p>Upload / Replace</p> -->
                </div>
              </el-upload>
            </div>
            <div>
              <!-- <div class="upload-basic"> -->
              <el-form-item prop="user" :rules="rules.required" style="margin-bottom: 10px" >
                <p class="text-tip">Author</p>
                <el-select v-model="blog.user" :popper-append-to-body="false">
                  <el-option
                    v-for="item in users"
                    :key="item.user_id"
                    :value="item.user_id"
                    :label="item.name"
                    class="row"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- </div> -->
              <!-- <div class="upload-basic"> -->
              <el-form-item prop="tags" style="margin-bottom: 10px">
                <p class="text-tip">Tags</p>
                <el-select
                  v-model="blog.tags"
                  filterable
                  :popper-append-to-body="false"
                  multiple
                >
                  <el-option
                    v-for="item in tagsD"
                    :key="item.tag_id"
                    :value="item.tag_id"
                    :label="item.name"
                    class="row"
                  >
                    <span style="font-size: 13px">{{ item.name }}</span>
                    <i
                      class="el-icon-close"
                      @click.prevent="delTag(item.tag_id)"
                    ></i>
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </div> -->
              <!-- <div class="upload-basic"> -->

              <el-form-item>
                <p class="text-tip">Add New Tag</p>
                <div class="row tag">
                  <el-input v-model="tag" />
                  <el-button
                    class="tag-btn"
                    @click="addTag"
                    icon="el-icon-plus"
                  ></el-button>
                </div>
              </el-form-item>
              <!-- </div> -->
            </div>
            <div>
              <el-form-item>
                <div class="text-form">
                  <p class="text-tip-options">Publishing Options</p>
                  <div class="select_method">
                    <el-radio-group v-model="blog.publish">
                      <p class="text-select">
                        <el-radio label="false">Draft</el-radio>
                      </p>
                      <p class="text-select">
                        <el-radio label="true">Publish now</el-radio>
                      </p>
                      <p>
                        <el-radio label="schedule">Schedule later</el-radio>
                      </p>
                    </el-radio-group>
                  </div>
                  <div class="blog-time" v-if="blog.publish === 'schedule'">
                    <el-date-picker
                      v-model="blog.schedule_time"
                      class="padding-right"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      format="MM/dd/yyyy hh:mm A"
                      type="datetime"
                      :picker-options="pickerOptions"
                      placeholder
                    ></el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="blog_edit_footer">
        <el-button class="block-button" @click="preview = true">Preview Post</el-button>
        <el-button class="primary-button" @click="updateBlogData('blog')" :loading="loading">Submit</el-button >
      </div>
      </div>
    </div>
    <!-- 查阅 -->
    <el-dialog title="Preview" :visible.sync="preview" @open="openPreviewDialog">
      <!-- <el-divider></el-divider> -->
      <div class="blog-html" v-html="blog.content"></div>
      <!-- <el-divider></el-divider> -->
      <span slot="footer" class="dialog-footer clear">
        <el-button @click="preview = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Ckeditors from "../ckeditor"
import { apiCreateBlog, apiGetBlog, apiUpdateBlog, apiUploadImage,  apiCreateTags, apiGetTags, apiDelTags, apiGetUsers } from "../../API/api";
export default {
  components: { Ckeditors },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      blog: {
        publish: "true",
        cover: "",
        title: "",
        tags: [],
        content: "",
        summary: "",
        words: 0,
      },
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      isClear: false,
      tag: "",
      users: [],
      tagsD: [],
      preview: false,
      loading: false,
      title: "Add New Blog",
      loadingImg: false,
    };
  },

  methods: {
    openPreviewDialog(){
      this.blog.content = this.$refs.edit.content; 
    },
    back() {
      this.$router.push({ name: "blogs" });
    },
    // 添加tag
    addTag() {
      apiCreateTags({ name: this.tag })
        .then((res) => {
          this.tag = "";
          this.getTags();
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    //  删除tag
    delTag(id) {
      apiDelTags(id).then((res) => {
        this.$message.success("Tag deleted");
        this.tagsD.forEach((n, i) => {
          if (id === n.tag_id) {
            this.tagsD.splice(i, 1);
          }
        });
      });
      window.event.cancelBubble = true;
    },
    // 上传blog的封面
    uploadCover(file) {
      var form = new FormData();
      form.append("file", file.file);
      this.loadingImg = true;
      apiUploadImage(form)
        .then((res) => {
          this.blog.cover = res.url;
        })
        .finally(() => {
          this.loadingImg = false;
        });
    },
    // 创建blog/修改
    updateBlogData(formName) {
      this.blog.content = this.$refs.edit.content;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {};
          obj = Object.assign({}, this.blog);
          if (obj.publish === "schedule") {
             obj.publish = false;
          } else {
           obj.schedule_time = null;
          }
          let label = obj.content;
          let pLength = label.replace(/<.*?>/gi, "");
          obj.words = pLength.length;
          this.loading = true;
          if (this.$route.params.id) {
            apiUpdateBlog(this.$route.params.id, obj)
              .then((res) => {
                this.$message.success("Updated successfully");
              })
              .catch((err) => {
                this.$message.error(err.response.data.detail);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            apiCreateBlog(obj)
              .then((res) => {
                this.$router.push({
                  name: "edit_blog",
                  params: { id: res.blog_id },
                });
                this.$message.success("Created successfully");
              })
              .catch((err) => {
                this.$message.error(err.response.data.detail);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    getUsers() {
      apiGetUsers({ limit: 50, user_type: ["admin", "pm"] }).then((res) => {
        this.users = res.results;
        this.users.forEach((item, index) => {
          let obj = { name: item.first_name + " " + item.last_name };
          item = Object.assign(item, obj);
        });
      });
    },
    getTags() {
      apiGetTags({ limit: 50 }).then((res) => {
        this.tagsD = res.results;
      });
    },
    // 获取blog的信息
    getBlog() {
      apiGetBlog(this.$route.params.id).then((res) => {
        this.blog = res;
        if (res.schedule_time) {
          this.blog.publish = "schedule";
          this.blog.schedule_time = res.schedule_time;
        } else {
          this.blog.publish = "" + res.publish + "";
        }
        let arr = [];
        this.blog.tags.forEach((item, index) => {
          arr.push(item.tag_id);
        });
        this.blog.tags = arr;
      });
    },
  },
  created() {
    this.getUsers();
    this.getTags();
    if (this.$route.params.id) {
      this.title = "Edit Blog";
      this.getBlog();
    } else {
      this.title = "Add New Blog";
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog_edit_box {
  .back-blogs {
    text-align: left;
    margin: 30px 0;
    .back-icon {
      color: #678993;
      font-size: 16px;
      font-family: "Roboto-Regular", sans-serif;
    }
  }
  .blog_edit_info {
    .add-new-blog {
      margin: 0 0 20px 0;
      font-weight: normal;
      font-size: 20px;
      font-family: "Roboto-Bold", sans-serif;
    }
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    .text-tip {
      margin: 0;
      color: #38425b;
      line-height: 20px;
      font-size: 14px;
      font-family: "Roboto-Regular", sans-serif;
    }
    .tag-btn {
      color: #fff;
      background-color: #678993;
      border-radius: 0 4px 4px 0;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0px 0 0 10px;
  }
  /deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    &::after {
      display: none;
    }
  }
  /deep/ .el-form-item__content {
    text-align: left;
  }
  /deep/ .el-select {
    width: 367px;
  }
  .info_form {
    padding: 20px 40px 0 40px;
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  // 弹出框
  /deep/ .el-dialog__header {
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #e5e5e5;
    .el-button {
      background-color: #fff;
      color: #555;
    }
  }
}

/* upload */
.blog_upload {
  margin-top: 50px;
  text-align: left;

  .upload_Img {
    justify-content: flex-start;
    align-items: flex-start;
    .upload-basic {
      margin-right: 40px;
    }
    .text-select {
      justify-content: flex-start;
      display: -webkit-flex;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
    .text-form {
      padding: 0 40px;
      .text-select {
        margin-bottom: 10px;
      }
      .text-tip-options {
        font-family: "Roboto-Bold", sans-serif;
        font-size: 18px;
        margin: 0;
      }
    }
    @media (max-width: 900px) {
      display: -webkit-flex;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
  }
  /deep/ .avatar-uploader .el-upload {
    background: #f4f9fc;
    border: 1px Dashed #678993;
    border-radius: 4px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  /deep/ .uploader_boder {
    height: 60px;
    position: absolute;
    left: 50px;
    top: 70px;
    p,
    i {
      color: #678993;
    }
    p {
      margin: 0;
      line-height: 15px;
    }
    i {
      font-size: 30px;
    }
  }
}
.avatar {
  width: 200px;
  height: 200px;
  object-fit: cover;
  display: block;
}
/*  */
.blog_edit_footer {
  margin-top: 40px;
  text-align: center;
  .primary-button,
  .block-button {
    font-family: "Roboto-Medium";
  }
}
// 选择 Publishing Options
.select_method {
  margin-bottom: 20px;
  /deep/ .el-radio__input .el-radio__inner {
    width: 20px;
    height: 20px;
    &::after {
      display: none;
    }
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border: none;
    background-image: url("../../assets/img/true.png");
    background-size: cover;
  }
  /deep/ .el-radio__inner:hover {
    border-color: #678993;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
}
/deep/ .ck-content .image>figcaption{
  line-height: initial;
}
/deep/.el-dialog {
  width: 1140px;
  .el-dialog__footer{ clear:both} 
  @media (max-width: 1200px) {
    width: 90%;
  }
}
// 弹出框
.blog-html {
  text-align: left;
  padding: 0 10px;
  
  /deep/ .image.image-style-align-left {
    float: left;
  }
  /deep/ .image.image-style-align-right {
    float: right;
  }
  /deep/ img {
    max-width: 100%;
    max-height: 1000px;
    object-fit: contain;
    height: auto;
  }
}
</style>
