
export const selectColors = 
[{
        color: "rgb(0,0,0)"
    },
    {
        color: "rgb(32,32,32)"
    },
    {
        color: "rgb(64,64,64)"
    },
    {
        color: "rgb(96,96,96)"
    },
    {
        color: "rgb(128,128,128)"
    },
    {
        color: "rgb(159,159,159)"
    },
    {
        color: "rgb(191,191,191)"
    },
    {
        color: "rgb(223,223,225)"
    },
    {
        color: "rgb(255,255,255)"
    },


    {
        color: "rgb(51,0,0)"
    },
    {
        color: "rgb(102,0,0)"
    },
    {
        color: "rgb(153,0,0)"
    },
    {
        color: "rgb(204,0,0)"
    },
    {
        color: "rgb(255,0,0)"
    },
    {
        color: "rgb(255,51,51)"
    },
    {
        color: "rgb(255,102,102)"
    },
    {
        color: "rgb(255,153,153)"
    },
    {
        color: "rgb(255,204,204)"
    },


    {
        color: "rgb(51,26,0)"
    },
    {
        color: "rgb(102,51,0)"
    },
    {
        color: "rgb(153,77,0)"
    },
    {
        color: "rgb(204,102,0)"
    },
    {
        color: "rgb(255,128,0)"
    },
    {
        color: "rgb(255,153,51)"
    },
    {
        color: "rgb(255,179,102)"
    },
    {
        color: "rgb(255,204,153)"
    },
    {
        color: "rgb(255,230,204)"
    },


    {
        color: "rgb(51,51,0)"
    },
    {
        color: "rgb(102,102,0)"
    },
    {
        color: "rgb(153,153,0)"
    },
    {
        color: "rgb(204,204,0)"
    },
    {
        color: "rgb(255,255,0)"
    },
    {
        color: "rgb(255,255,51)"
    },
    {
        color: "rgb(255,255,102)"
    },
    {
        color: "rgb(255,255,153)"
    },
    {
        color: "rgb(255,255,204)"
    },


    {
        color: "rgb(26,51,0)"
    },
    {
        color: "rgb(51,102,0)"
    },
    {
        color: "rgb(77,153,0)"
    },
    {
        color: "rgb(102,204,0)"
    },
    {
        color: "rgb(128,255,0)"
    },
    {
        color: "rgb(153,255,51)"
    },
    {
        color: "rgb(179,255,102)"
    },
    {
        color: "rgb(204,255,153)"
    },
    {
        color: "rgb(230,255,204)"
    },



    {
        color: "rgb(0,51,0)"
    },
    {
        color: "rgb(0,102,0)"
    },
    {
        color: "rgb(0,153,0)"
    },
    {
        color: "rgb(0,204,0)"
    },
    {
        color: "rgb(0,255,0)"
    },
    {
        color: "rgb(51,255,51)"
    },
    {
        color: "rgb(102,255,102)"
    },
    {
        color: "rgb(153,255,153)"
    },
    {
        color: "rgb(204,255,204)"
    },


    {
        color: "rgb(0,51,26)"
    },
    {
        color: "rgb(0,102,51)"
    },
    {
        color: "rgb(0,153,77)"
    },
    {
        color: "rgb(0,204,102)"
    },
    {
        color: "rgb(0,255,128)"
    }, {
        color: "rgb(51,255,153)"
    },
    {
        color: "rgb(102,255,179)"
    },
    {
        color: "rgb(153,255,204)"
    },
    {
        color: "rgb(204,255,230)"
    },


    {
        color: "rgb(0,51,51)"
    },
    {
        color: "rgb(0,102,102)"
    },
    {
        color: "rgb(0,153,153)"
    },
    {
        color: "rgb(0,204,204)"
    },
    {
        color: "rgb(0,255,255)"
    }, {
        color: "rgb(51,255,255)"
    },
    {
        color: "rgb(102,255,255)"
    },
    {
        color: "rgb(153,255,255)"
    },
    {
        color: "rgb(204,255,255)"
    },

    {
        color: "rgb(0,26,51)"
    },
    {
        color: "rgb(0,51,102)"
    },
    {
        color: "rgb(0,77,153)"
    },
    {
        color: "rgb(0,102,204)"
    },
    {
        color: "rgb(0,128,255)"
    }, {
        color: "rgb(51,153,255)"
    },
    {
        color: "rgb(102,179,255)"
    },
    {
        color: "rgb(153,204,255)"
    },
    {
        color: "rgb(204,230,255)"
    },


    {
        color: "rgb(0,0,51)"
    },
    {
        color: "rgb(0,0,102)"
    },
    {
        color: "rgb(0,0,153)"
    },
    {
        color: "rgb(0,0,204)"
    },
    {
        color: "rgb(0,0,255)"
    }, {
        color: "rgb(51,51,255)"
    },
    {
        color: "rgb(102,102,255)"
    },
    {
        color: "rgb(153,153,255)"
    },
    {
        color: "rgb(204,204,255)"
    },



    {
        color: "rgb(26,0,51)"
    },
    {
        color: "rgb(51,0,102)"
    },
    {
        color: "rgb(77,0,153)"
    },
    {
        color: "rgb(102,0,204)"
    },
    {
        color: "rgb(128,0,255)"
    }, {
        color: "rgb(153,51,255)"
    },
    {
        color: "rgb(179,102,255)"
    },
    {
        color: "rgb(204,153,255)"
    },
    {
        color: "rgb(230,204,255)"
    },

    {
        color: "rgb(51,0,51)"
    },
    {
        color: "rgb(102,0,102)"
    },
    {
        color: "rgb(153,0,153)"
    },
    {
        color: "rgb(204,0,204)"
    },
    {
        color: "rgb(255,0,255)"
    }, {
        color: "rgb(255,51,255)"
    },
    {
        color: "rgb(255,102,255)"
    },
    {
        color: "rgb(255,153,255)"
    },
    {
        color: "rgb(255,204,255)"
    },

    {
        color: "rgb(51,0,26)"
    },
    {
        color: "rgb(102,0,51)"
    },
    {
        color: "rgb(153,0,77)"
    },
    {
        color: "rgb(204,0,102)"
    },
    {
        color: "rgb(255,0,128)"
    }, {
        color: "rgb(255,51,153)"
    },
    {
        color: "rgb(255,102,179)"
    },
    {
        color: "rgb(255,153,204)"
    },
    {
        color: "rgb(255,204,230)"
    },

    {color:"#1C4A5E"},
    {color:"#5E7B87"},
    {color:"#6D8994"},
    {color:"#8BABB6"},
    {color:"#EEF2F4"}

]