<template>
  <div class="ckedit">
    <ckeditor
      :editor="editor"
      @ready="onReady"
      v-model="content"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>
<script>
import { apiUploadImage } from "../../API/api";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
// ckeditor5-special-characters
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters";
import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials";

// ckeditor5-alignment
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
// ckeditor5-indent
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
// ckeditor5-highlight
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
// ckeditor5-list
import List from "@ckeditor/ckeditor5-list/src/list";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
// ckeditor5-font
import Font from "@ckeditor/ckeditor5-font/src/font";
// ckeditor5-link
import Link from "@ckeditor/ckeditor5-link/src/link";
// ckeditor5-heading
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
// ckeditor5-paragraph
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
// ckeditor5-essentials
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
// ckeditor5-media-embed/
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
// table
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import toolbarview from "@ckeditor/ckeditor5-ui/src/toolbar/toolbarview";
// import template from "@ckeditor/ckeditor5-ui/src/template";
// import Model from "@ckeditor/ckeditor5-ui/src/model";
// image
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";

import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
//  代码块
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
// 字数
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import { selectColors } from "./color";
export default {
  components: { ckeditor: CKEditor.component },
  props: ["data"],
  data() {
    return {
      content: "",
      editor: ClassicEditor,
      editorConfig: {
        // placeholder: this.placeholder,
        forcePasteAsPlainText: true,
        fontColor: {
          colors: selectColors,
          columns: 9,
        },
        fontBackgroundColor: {
          colors: selectColors,
          columns: 9,
        },
        fontSize: {
          options: [8, 10, "default", 14, 16, 18, 20, 22, 24, 26, 28, 32,64],
        },
        fontFamily: {
          options: [
            "宋体",
            "仿宋",
            "微软雅黑",
            "黑体",
            "仿宋_GB2312",
            "楷体",
            "隶书",
            "幼圆",
            "Andale Mono",
            "Arial",
            "Arial Black",
            "Book Antiqua",
            "Comic Sans MS",
            "Courier New",
            "Georgia",
            "Helvetica",
            "Poppins",
            "Symbol",
            "Tahoma",
            "Terminal",
            "Times New Roman",
            "Trebuchet MS",
            "Verdana",
          ],
        },
        plugins: [
          SpecialCharacters,
          SpecialCharactersEssentials,
          WordCount,
          Table,
          Font,
          Link,
          TodoList,
          List,
          Code,
          Bold,
          Indent,
          IndentBlock,
          Italic,
          Heading,
          Paragraph,
          Highlight,
          Underline,
          Alignment,
          Essentials,
          TableToolbar,
          Strikethrough,
          BlockQuote,
          toolbarview,
          Image,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageCaption,
          ImageStyle,
          ImageResize,
          ImageUpload,
          ImageToolbar,
          CodeBlock,
          ListStyle,
          HorizontalLine,
          MediaEmbed
        ],
        toolbar: {
          shouldNotGroupWhenFull: true,
          // options: {
          //   shouldGroupWhenFull: false,
          // },
          items: [
            "heading",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "highlight",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "blockQuote",
            "|",
            "specialCharacters",
            "insertTable",
            "imageUpload",
            "mediaEmbed",
            //  "Image", "ImageResizeEditing", "ImageResizeHandles",
            "link",
            "code",
            "CodeBlock",
            "|",
            "alignment",
            "HorizontalLine",
            "|",
            "indent",
            "outdent",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        indentBlock: {
          offset: 1,
          unit: "em",
        },
        image: {
          resizeUnit: "px",
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
            "imageStyle:alignBlockLeft",
            "imageStyle:alignBlockRight",
            "imageStyle:inline",
            "imageStyle:block",
            "|",
            "imageTextAlternative",
            "toggleImageCaption",
          ],
          styles: {
            options: [
              "block",
              "inline",
              "alignLeft",
              "alignRight",
              "alignBlockLeft",
              "alignBlockRight",
            ],
          },
        },
        codeBlock: {
          languages: [
            { language: "plaintext", label: "Plain text", class: "" },
            { language: "c", label: "C" },
            { language: "cs", label: "C#" },
            { language: "cpp", label: "C++" },
            { language: "css", label: "CSS" },
            { language: "diff", label: "Diff" },
            { language: "html", label: "HTML" },
            { language: "java", label: "Java" },
            { language: "javascript", label: "JavaScript" },
            { language: "php", label: "PHP" },
            { language: "python", label: "Python" },
            { language: "ruby", label: "Ruby" },
            { language: "typescript", label: "TypeScript" },
            { language: "xml", label: "XML" },
          ],
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: "manual",
              label: "Open in a new tab",
              defaultValue: false, // This option will be selected by default.
              attributes: { target: "_blank" },
            },
          },
        },
      },
    };
  },
  watch: {
    data(newv) {
      this.content = this.data;
    },
  },
  methods: {
    onReady(editor) {
      // 自定义上传图片插件
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        // return new MyUploadAdapter(loader);
        return {
          upload: async () => {
            const file = await loader.file;
            return new Promise(async (resolve) => {
              let data = new FormData();
              data.append("file", file);
              await apiUploadImage(data)
                .then((res) => {
                  resolve({ default: res.url });
                })
                .catch((err) => {
                  this.$message("Upload failure");
                });
            });
          },
        };
      };
      // editor.plugins.get( 'WordCount' ).on( 'update', ( evt, stats ) => {
      // Prints the current content statistics.
      //  console.log( `Characters: ${ stats.characters }\nWords:      ${ stats.words }` ,stats);
      //  this.data.words = stats.words

      // } );
    },
  },
  computed: {
    getColors() {
      return selectColors || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.ckedit {
  /deep/ .ck.ck-editor {
    .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
      overflow: auto;
      max-height: 400px;
    }
    .image.ck-widget {
      // padding: 16px 20px 0 0;
    }
    p,
    li {
      // margin: 0;
      line-height: 24px;
    }
  }
}
</style>
